import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import {
	FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { useMap } from 'react-map-gl';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useCapsulesFromRouteSlug } from '../../data/Capsule.swr';
import { MapStore } from '../../data/MapStore';
import { DirectionProfiles } from '../../models/MapBox';
import { Route } from '../../models/Route';

import { MapOneParcours } from './MapOneParcours';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';

interface Props {
	routes: Route[];
	directionProfile: DirectionProfiles;
}

const StyledContainer = styled.div`
	position: absolute;
	bottom: ${(props) => 12 / props.theme.fontSizeBase}rem;
	left: 0;
	width: 100%;
	height: ${(props) => 250 / props.theme.fontSizeBase}rem;
	z-index: 10;

	.swiper {
		width: 100%;
		height: 100%;
	}

	.swiper-slide {
		width: 85vw;
		max-width: 280px;
	}
`;

export const MapParcours: FunctionComponent<Props> = ({ routes, directionProfile }) => {
	const ref = useRef<HTMLDivElement>(null);
	const router = useRouter();
	const { default: map } = useMap();
	const [isZoomed, setIsZoomed] = useState(false);
	const { parcoursSelected, heightOffsetButtons } = MapStore.useState((s) => s);
	const { data: capsules } = useCapsulesFromRouteSlug(parcoursSelected, router.locale || 'fr');

	useEffect(() => {
		MapStore.update((s) => {
			if (ref.current && ref.current.clientHeight > 0) {
				s.heightOffsetButtons = ref.current.clientHeight + 12;
			}
		});
		return () => {
			MapStore.update((s) => {
				s.heightOffsetButtons = 0;
			});
		};
	}, []);

	useEffect(() => {
		MapStore.update((s) => {
			if (s.parcoursSelected === null) {
				s.parcoursSelected = routes[0].attributes.slug;
			}
		});
	}, [routes]);

	const onSlideChange = useCallback((slider: { realIndex: number }) => {
		const route = routes.find((_, index) => (index === slider.realIndex));
		if (route) {
			MapStore.update((s) => {
				s.parcoursSelected = route.attributes.slug;
				setIsZoomed(false);
			});

			if (map) {
				routes.forEach((r) => {
					const uniqueId = `parcours-${r.attributes.slug}`;
					if (r.attributes.slug === route.attributes.slug && map.getMap().getSource(uniqueId)) {
						map.getMap().setLayoutProperty(uniqueId, 'visibility', 'visible');
					} else if (
						r.attributes.slug !== route.attributes.slug && map.getMap().getSource(uniqueId)
					) {
						map.getMap().setLayoutProperty(uniqueId, 'visibility', 'none');
					}
				});
			}
		}
	}, [map, routes]);

	const clickParcours = useCallback((slug: string) => {
		router.push({
			pathname: `/map/parcours/${slug}`,
			query:    {
				directionProfile,
			},
		});
	}, [directionProfile, router]);

	useEffect(() => {
		if (capsules && !isZoomed && heightOffsetButtons > 0) {
			try {
				map?.flyTo({
					center: [
						capsules[0].attributes.longitude,
						capsules[0].attributes.latitude,
					],
					zoom:    17,
					padding: {
						top:    0,
						bottom: Math.round(heightOffsetButtons / 2),
						left:   0,
						right:  0,
					},
				});
			} catch (error) {
				captureException(error);
			}
			setIsZoomed(true);
		}
	}, [capsules, heightOffsetButtons, isZoomed, map]);

	return (
		<StyledContainer ref={ref}>
			<Swiper
				slidesPerView={'auto'}
				spaceBetween={8}
				slidesOffsetBefore={12}
				slidesOffsetAfter={12}
				onSlideChange={onSlideChange}
			>
				{ routes.map((route, index) => (
					<SwiperSlide key={`parcours-${index}`}>
						<MapOneParcours
							directionProfile={directionProfile}
							onClick={clickParcours}
							route={route}
						/>
					</SwiperSlide>
				)) }
			</Swiper>
		</StyledContainer>
	);
};
