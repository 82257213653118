import styled from 'styled-components';

interface Props {
	title: string;
	description: string;
}

const StyledContainer = styled.div`
	display: flex;
	justify-content: stretch;
	flex-direction: column;
	flex: 1;
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 8 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;
`;

const StyledTitle = styled.p`
	padding: 0;
	margin: 0;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 15 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;

	letter-spacing: -1px;
	color: ${(props) => props.theme.black};
`;

const StyledDescription = styled.p`
	padding: 0;
	margin: ${(props) => 4 / props.theme.fontSizeBase}rem 0 0 0;
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 10 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;

	letter-spacing: -0.5px;
	color: ${(props) => props.theme.black};
	opacity: 0.4;
`;

// TODO: Memo
export const MapOneParcoursDescription = ({ title, description }: Props) => (
	<StyledContainer>
		<StyledTitle>{ title }</StyledTitle>
		<StyledDescription>{ description }</StyledDescription>
	</StyledContainer>
);
