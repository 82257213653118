import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import Theme from '../../styles/Theme';

interface Props {
	className?: string;
	color: string;
	size: number; // in Px
	lineHeight: number; // in Px
	weight: number;

	opacity?: number;

	marginBottom?: number;
}

const StyledText = styled.p<{
	$size: number,
	$lineHeight: number,
	$weight: number,
	$color: string,
	$opacity: number,
	$marginBottom: number,
}>`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;

	// COLOR
	${(props) => `
		color: ${props.$color};
	`};

	// SIZE
	${(props) => `
		font-size: ${props.$size / props.theme.fontSizeBase}rem;
	`};
	${(props) => `
		line-height: ${props.$lineHeight / props.theme.fontSizeBase}rem;
	`};

	// WEIGHT
	${(props) => `
		font-weight: ${props.$weight};
	`};

	// OPACITY
	${(props) => `
		opacity: ${props.$opacity};
	`};

	// MARGIN
	${(props) => `
		margin-bottom: ${props.$marginBottom / props.theme.fontSizeBase}rem;
	`};
`;

const Text = ({
	className,
	color = Theme.black,
	size = 14,
	lineHeight = 16,
	weight = 400,
	opacity = 1,
	marginBottom = 0,
	children,
}: PropsWithChildren<Props>) => (
	<StyledText
		className={className}
		$color={color}
		$size={size}
		$lineHeight={lineHeight}
		$weight={weight}
		$opacity={opacity}
		$marginBottom={marginBottom}
	>
		{ children }
	</StyledText>
);

export default Text;
