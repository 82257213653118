import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';

import { useRouter } from 'next/router';
import { useCapsulesFromRouteSlug } from '../../data/Capsule.swr';
import { MapStore } from '../../data/MapStore';
import { useMapBoxDirections } from '../../data/Mapbox.swr';
import { DirectionProfiles } from '../../models/MapBox';
import { Route } from '../../models/Route';

import Directions from './Directions';
import { MapOneParcoursCapsules } from './MapOneParcoursCapsules';
import { MapOneParcoursCategory } from './MapOneParcoursCategory';
import { MapOneParcoursDescription } from './MapOneParcoursDescription';
import { MapOneParcoursDirections } from './MapOneParcoursDirections';
import MapOneParcoursInfos from './MapOneParcoursInfos';

interface Props {
	// category: string;
	// categoryColor: 'black' | 'orange';
	// title: string;
	// description: string;
	// pinsIcons: Array<CapsulesIcons>;
	route: Route;
	onClick: (slug: string) => void;
	directionProfile: DirectionProfiles;
}

const StyledOneParcours = styled.div`
	background: ${(props) => props.theme.white};
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-shadow: 0 2px 2px rgba(32, 32, 32, 0.06);
	border-radius: ${(props) => 12 / props.theme.fontSizeBase}rem;
	width: 100%;
	cursor: pointer;

	/* width: 75%; */
`;

export const MapOneParcours: FunctionComponent<Props> = ({
	route, onClick, directionProfile,
}: Props) => {
	const { locale } = useRouter();
	const {
		parcoursSelected, mapWalk, mapBike, mapCar, mapPath,
	} = MapStore.useState((s) => s);
	const { data: capsules } = useCapsulesFromRouteSlug(route.attributes.slug, locale || 'fr');

	const icons = useMemo(() => {
		if (!capsules) {
			return [];
		}

		return capsules.map((capsule) => capsule.attributes.icon);
	}, [capsules]);

	const points = useMemo(() => {
		const pointsValue: Array<Array<number>> = [];
		capsules?.forEach((capsule) => {
			pointsValue.push([capsule.attributes.longitude, capsule.attributes.latitude]);
		});
		return pointsValue;
	}, [capsules]);

	const { data: mapBoxDirectionsWalking } = useMapBoxDirections(mapWalk ? 'walking' : null, points);
	const { data: mapBoxDirectionsCycling } = useMapBoxDirections(mapBike ? 'cycling' : null, points);
	const { data: mapBoxDirectionsDriving } = useMapBoxDirections(mapCar ? 'driving' : null, points);

	const totalDuration = useMemo(() => {
		let duration = 0;
		capsules?.forEach((c) => {
			duration += c.attributes.duration;
		});
		return duration;
	}, [capsules]);

	return (
		<StyledOneParcours onClick={() => onClick(route.attributes.slug)}>
			{ capsules && parcoursSelected === route.attributes.slug && capsules.length > 0
			&& mapPath && <>
				<Directions
					profile={directionProfile}
					points={points}
					uniqueId={`parcours-${route.attributes.slug}`}
					key={`parcours-${route.attributes.slug}`}
				/>
			</>}
			<MapOneParcoursCategory text={route.attributes.category} color={parcoursSelected === route.attributes.slug ? 'orange' : 'black'}/>
			<MapOneParcoursDescription
				title={route.attributes.title}
				description={route.attributes.description}
			/>

			<MapOneParcoursDirections
				walk={mapBoxDirectionsWalking?.duration}
				bike={mapBoxDirectionsCycling?.duration}
				car={mapBoxDirectionsDriving?.duration}
			/>

			<MapOneParcoursInfos
				distance={mapBoxDirectionsWalking?.distance}
				totalDuration={totalDuration}
			/>

			<MapOneParcoursCapsules icons={icons} color={parcoursSelected === route.attributes.slug ? 'orange' : 'black'} />
		</StyledOneParcours>
	);
};
