import styled, { css } from 'styled-components';

import { useTranslation } from 'next-i18next';
import Theme from '../../styles/Theme';
import { CapsulesIcons } from '../../models/MapBox';

import { CapsuleIcon } from './capsules/CapsuleIcon';

interface Props {
	icons: Array<CapsulesIcons>;
	color: 'orange' | 'black';
}

const StyledContainer = styled.div`
	padding: ${(props) => 12 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;
`;

const StyledIconsContainer = styled.div`
	display: flex;
	color: ${(props) => props.theme.white};
`;

const StyledIcon = styled.div<{color: 'orange' | 'black'}>`
	width: ${(props) => 34 / props.theme.fontSizeBase}rem;
	height: ${(props) => 34 / props.theme.fontSizeBase}rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${(props) => 34 / props.theme.fontSizeBase}rem;
	border: ${(props) => 2 / props.theme.fontSizeBase}rem solid white;
	margin: 0 -${(props) => 4 / props.theme.fontSizeBase}rem;
	position: relative;

	${(props) => props.color === 'orange' && css`
		background: ${props.theme.mapOrange};
	`};

	${(props) => props.color === 'black' && css`
		background: ${props.theme.black};
	`};
`;

const StyledText = styled.p`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	letter-spacing: -1px;
	color: ${(props) => props.theme.mapCapsulesText};
	margin: 0 0 ${(props) => 8 / props.theme.fontSizeBase}rem 0;
`;

export const MapOneParcoursCapsules = ({ icons, color }: Props) => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			<StyledText>
				{icons.length}
				{'\u00a0\u00a0'}
				{t('common:mapCapsules')}
			</StyledText>
			<StyledIconsContainer>
				{ icons.map((icon, index) => (
					<StyledIcon key={`icon-${index}`} color={color} >
						<CapsuleIcon size={22.67} color={Theme.white} icon={icon}/>
					</StyledIcon>
				)) }
			</StyledIconsContainer>
		</StyledContainer>
	);
};
