import { captureException } from '@sentry/nextjs';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import styled from 'styled-components';

import { MapBox } from '../../components/map/MapBox';
import { MapButtons } from '../../components/map/MapButtons';
import { MapHeader } from '../../components/map/MapHeader';
import { MapOneCapsule } from '../../components/map/MapOneCapsule';
import { MapParcours } from '../../components/map/MapParcours';
import { useSreenHeight } from '../../custom/useScreenHeight';
import { useCapsulesFromClientId } from '../../data/Capsule.swr';
import { MapStore } from '../../data/MapStore';
import { useRoutes } from '../../data/Routes.swr';
import { Capsule } from '../../models/Capsule';
import { DirectionProfiles } from '../../models/MapBox';
import { NextPageProps } from '../../typings/NextPageProps';
import { convertCapsulesToFeatures } from '../../utils/geojson';
import { checkClient } from '../../utils/serverSide';
import MapOneCapsuleDesktop from '../../components/map/MapOneCaspuleDesktop';
import { Device, useDevice } from '../../custom/useDevice';

const StyledContainer = styled.div<{screenHeight: number}>`
	display: flex;
	height: ${(props) => props.screenHeight}px;
	width: 100%;
	align-items: stretch;
	flex-direction: column;
`;

export const Map: NextPage<NextPageProps> = ({
	seo, client,
}: NextPageProps) => {
	const router = useRouter();
	const screenHeight = useSreenHeight();
	const { capsuleSelected, parcoursSelected, heightOffsetButtons } = MapStore.useState((s) => s);
	const { display } = MapStore.useState((s) => s);
	const [isZoomed, setIsZoomed] = useState(false);
	const { default: map } = useMap();
	const device = useDevice();

	const { data: routes } = useRoutes(client.id, router.locale || 'fr');
	const { data: capsules } = useCapsulesFromClientId(client.id, parcoursSelected, router.locale || 'fr');

	const directionProfile: DirectionProfiles = router.query.directionProfile as DirectionProfiles || 'walking';

	const onClickToGo = useCallback((capsule: Capsule) => {
		router.push({
			pathname: `/map/capsule/${capsule.attributes.slug}/toGo`,
			query:    {
				directionProfile,
			},
		});
	}, [directionProfile, router]);

	const onClickToPlay = useCallback((capsule: Capsule) => {
		router.push({
			pathname: `/map/capsule/${capsule.attributes.slug}/player`,
			query:    {
				directionProfile,
			},
		});
	}, [directionProfile, router]);

	useEffect(() => {
		if (capsuleSelected && !isZoomed && heightOffsetButtons > 0) {
			try {
				map?.flyTo({
					center: [
						capsuleSelected.attributes.longitude,
						capsuleSelected.attributes.latitude,
					],
					zoom:    17,
					padding: {
						top:    0,
						bottom: Math.round(heightOffsetButtons / 2),
						left:   0,
						right:  0,
					},
				});
			} catch (error) {
				captureException(error);
			}
			setIsZoomed(true);
		}
	}, [capsuleSelected, isZoomed, map, heightOffsetButtons]);

	useEffect(() => {
		if (client) {
			MapStore.update((s) => {
				s.mapPath = !!client.attributes.mapPath;
				s.mapWalk = !!client.attributes.mapWalk;
				s.mapBike = !!client.attributes.mapBike;
				s.mapCar = !!client.attributes.mapCar;
			});
		}
	}, [client]);

	return (
		<>
			<NextSeo {...seo} />
			{ screenHeight
			&& <StyledContainer screenHeight={screenHeight}>
				<MapHeader title={client.attributes.title} translation={client.attributes.translations} />
				<MapBox capsules={convertCapsulesToFeatures(capsules || [])}>
					{display === 'parcours' && routes && <MapParcours directionProfile={directionProfile} routes={routes} /> }

					{ (device === Device.DESKTOP
					|| device === Device.TABLET_LANDSCAPE
					|| device === Device.TABLET_PORTRAIT)
					&& capsuleSelected
					&& <MapOneCapsuleDesktop
						directionProfile={directionProfile}
						capsule={capsuleSelected}
						onClickToPlay={onClickToPlay}
						onClickToGo={onClickToGo}
					/>}

					{ device === Device.MOBILE && capsuleSelected && <MapOneCapsule
						directionProfile={directionProfile}
						capsule={capsuleSelected}
						onClickToPlay={onClickToPlay}
						onClickToGo={onClickToGo}
						canBeHidden={true}
					/> }

					{ routes && <MapButtons defaultRouteSlug={routes[0].attributes.slug} /> }
				</MapBox>
			</StyledContainer> }
		</>
	);
};

export const getServerSideProps: GetServerSideProps = (context) => checkClient(context, ['map'], ['common']);

export default Map;
