import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

interface Props {
	text: string;
	color: 'orange' | 'black';
}

const StyledContainer = styled.div`
	padding: ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 8 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;
`;

const StyledCategory = styled.div<{ color: 'orange' | 'black' }>`
	width: fit-content;
	height: ${(props) => 24 / props.theme.fontSizeBase}rem;
	border-radius: ${(props) => 40 / props.theme.fontSizeBase}rem;
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 8 / props.theme.fontSizeBase}rem;

	color: ${(props) => props.theme.white};

	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 600;
	font-size: ${(props) => 9 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	text-transform: uppercase;

	${(props) => props.color === 'orange' && css`
		background: ${props.theme.mapOrange};
	`}

	${(props) => props.color === 'black' && css`
		background: ${props.theme.mapBlack};
	`}
`;

// TODO: Memo
export const MapOneParcoursCategory: FunctionComponent<Props> = ({ text, color }: Props) => (
	<StyledContainer>
		<StyledCategory color={color}>
			{ text }
		</StyledCategory>
	</StyledContainer>
);
