import {
	FunctionComponent, useCallback, useEffect, useMemo, useRef,
} from 'react';
import styled from 'styled-components';

import { useTranslation } from 'next-i18next';
import Theme from '../../styles/Theme';
import { MapStore } from '../../data/MapStore';
import { useMapBoxDirections } from '../../data/Mapbox.swr';
import { Capsule } from '../../models/Capsule';
import { DirectionProfiles } from '../../models/MapBox';
import { MapButton } from './MapButton';
import { MapOneCapsuleInfos } from './MapOneCapsuleInfos';
import { IconPointerSVG } from './svg/IconPointerSVG';
import Text from '../ui/Text';
import Flex from '../ui/Flex';
import { IconCrossSVG } from './svg/IconCrossSVG';
import { Device, useDevice } from '../../custom/useDevice';

interface Props {
	capsule: Capsule;
	directionProfile: DirectionProfiles;

	onClickToGo: (capsule: Capsule) => void;
	onClickToPlay: (capsule: Capsule) => void;
}

const StyledOneCapsule = styled.div<{$fullWidth: boolean}>`
	position: absolute;
	bottom: ${(props) => 16 / props.theme.fontSizeBase}rem;
	left: ${(props) => 16 / props.theme.fontSizeBase}rem;
	z-index: 10;
	width: ${(props) => 375 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.white};
	padding: ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 16 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;

	box-shadow: 0px -1px ${(props) => 8 / props.theme.fontSizeBase}rem rgba(34, 9, 2, 0.1);


	img {
		border-radius: ${(props) => 8 / props.theme.fontSizeBase}rem;
		width: 100%;
		object-fit: cover;
		max-width: ${(props) => 335 / props.theme.fontSizeBase}rem;
	}

	button {
		width: 50%;
	}

	${(props) => !props.$fullWidth && `
		border-radius: ${20 / props.theme.fontSizeBase}rem;
	`};

	${(props) => props.$fullWidth && `
		width: 100%;
		height: ${305 / props.theme.fontSizeBase}rem;

		border-top-left-radius: ${20 / props.theme.fontSizeBase}rem;
		border-top-right-radius: ${20 / props.theme.fontSizeBase}rem;

		img {
			width: 50%;
			max-width: 50%;
			max-height: ${265 / props.theme.fontSizeBase}rem;
		}
	`};
`;

const StyledContent = styled(Flex)`
	position: relative;
`;

const StyledClose = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: ${(props) => 28 / props.theme.fontSizeBase}rem;
	right: ${(props) => 28 / props.theme.fontSizeBase}rem;

	width: ${(props) => 40 / props.theme.fontSizeBase}rem;
	height: ${(props) => 40 / props.theme.fontSizeBase}rem;
	border-radius: 50%;
	background: ${(props) => props.theme.white};

	cursor: pointer;
`;

const MapOneCapsuleDesktop: FunctionComponent<Props> = ({
	capsule, onClickToGo, onClickToPlay, directionProfile,
}: Props) => {
	const { t } = useTranslation(['common']);
	const ref = useRef<HTMLDivElement>(null);
	const { userLocation } = MapStore.useState((s) => s);
	const device = useDevice();

	const points = useMemo(() => {
		if (capsule && userLocation) {
			return [
				[capsule.attributes.longitude, capsule.attributes.latitude],
				[userLocation.longitude, userLocation.latitude],
			];
		}
		return [];
	}, [capsule, userLocation]);

	const { data: mapBoxDirections } = useMapBoxDirections(directionProfile, points);

	const onClose = useCallback(() => {
		MapStore.update((s) => {
			s.capsuleSelected = null;
		});
	}, []);

	const setOffsetHeight = useCallback(() => {
		if (device === Device.TABLET_PORTRAIT) {
			MapStore.update((s) => {
				if (ref.current && ref.current.clientHeight > 0) {
					s.heightOffsetButtons = ref.current.clientHeight + 9;
				}
			});
		}
	}, [device]);

	useEffect(() => {
		setOffsetHeight();

		return () => {
			MapStore.update((s) => {
				s.heightOffsetButtons = 0;
			});
		};
	}, [setOffsetHeight]);

	return (
		<StyledOneCapsule ref={ref} $fullWidth={device === Device.TABLET_PORTRAIT}>
			<StyledContent direction='column' gap={16}>
				{ (device !== Device.TABLET_PORTRAIT)
				&& <StyledClose onClick={onClose}>
					<IconCrossSVG width={20} height={20} color={Theme.textPrimary} />
				</StyledClose> }

				<Flex direction={(device !== Device.TABLET_PORTRAIT) ? 'column' : 'row'} gap={20}>
					{ capsule.attributes.images.data && <img
						src={capsule.attributes.images.data[0].attributes.url}
						alt={capsule.attributes.images.data[0].attributes.alternativeText}
						onLoad={setOffsetHeight}
					/> }

					<Flex direction='column' gap={16} flex={'1'}
						justifyContent={device !== Device.TABLET_PORTRAIT ? 'flex-start' : 'center'}
					>
						<div>
							<Text weight={600} size={19} lineHeight={24} color={Theme.black} marginBottom={4}>
								{ capsule.attributes.title }
							</Text>
							<Text weight={500} size={14} lineHeight={16} color={Theme.black} opacity={0.4}>
								{
									capsule.attributes.subTitle
										? capsule.attributes.subTitle : capsule.attributes.title
								}
							</Text>

							<MapOneCapsuleInfos
								duration={capsule.attributes.duration}
								distance={mapBoxDirections?.distance}
								distanceDuration={mapBoxDirections?.duration}
								directionProfile={directionProfile}
							/>

							<Flex gap={8}>
								<MapButton icon={directionProfile} onClick={() => onClickToGo(capsule)}>{ t('common:mapToGo') }</MapButton>
								<MapButton icon="play" light onClick={() => onClickToPlay(capsule)}>{ t('common:mapPlayCapsule') }</MapButton>
							</Flex>
						</div>

						<Flex direction="row" gap={4}>
							<IconPointerSVG width={24} height={24} color={Theme.black} />
							<Text weight={500} size={14} lineHeight={24} color={Theme.black2}>
								{ capsule.attributes.address }

								{ (capsule.attributes.postalCode || capsule.attributes.city) && <>
									{ device !== Device.TABLET_PORTRAIT && <br/> }
									{ device === Device.TABLET_PORTRAIT && <span> </span> }

									{ `${capsule.attributes.postalCode || ''} ${capsule.attributes.city || ''}` }
								</>}
							</Text>
						</Flex>
					</Flex>
				</Flex>

			</StyledContent>
		</StyledOneCapsule>
	);
};

export default MapOneCapsuleDesktop;
