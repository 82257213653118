import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'next-i18next';
import { meterToString } from '../../utils/distance';
import { convertMinuteToString } from '../../utils/time';
import Theme from '../../styles/Theme';
import { IconListenSVG } from './svg/IconListenSVG';
import { IconDistanceSVG } from './svg/IconDistanceSVG';

interface Props {
	distance?: number; // meter
	totalDuration?: number;
}

const StyledContainer = styled.div`
	flex: 1;
	padding: ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 8 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;
	display: flex;
	border-bottom: 1px solid rgba(0, 0, 0, 0.10);
`;

const StyledSubContainer = styled.div`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.black3};

	gap: ${(props) => 8 / props.theme.fontSizeBase}rem;

	div {
		display: flex;
		align-items: center;
		gap: ${(props) => 4 / props.theme.fontSizeBase}rem;
	}
`;

const StyledText = styled.span`
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-style: normal;
	font-weight: 500;
	color: ${(props) => props.theme.black3};
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: 100%;
`;

const StyledSeparator = styled.div`
	width: 1px;
	height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.mapSeparator};
	margin-right: ${(props) => 8 / props.theme.fontSizeBase}rem;
`;

const MapOneParcoursInfos: FunctionComponent<Props> = ({ distance, totalDuration }: Props) => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			<StyledSubContainer>
				<div>
					<IconDistanceSVG width={16} height={16} color={Theme.black} />
					<StyledText>
						{ distance ? meterToString(t, distance) : '...' }
					</StyledText>
				</div>

				<div>
					<StyledSeparator></StyledSeparator>
					<IconListenSVG width={16} height={16} color={Theme.black} />

					<StyledText>{ convertMinuteToString(t, totalDuration || 0) }</StyledText>
				</div>
			</StyledSubContainer>
		</StyledContainer>
	);
};

export default MapOneParcoursInfos;
