import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type FlexDirections = 'row' | 'column';
type FlexJustifyContent = 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
type FlexAlignItems = 'center';

interface Props {
	className?: string;
	direction?: FlexDirections;
	justifyContent?: FlexJustifyContent;
	alignItems?: FlexAlignItems;

	flex?: string;
	gap?: number; // In px
}

const StyledFlex = styled.div<{
	$direction: FlexDirections,
	$justifyContent?: FlexJustifyContent,
	$alignItems?: FlexAlignItems,
	$flex: string,
	$gap: number,
}>`
	display: flex;
	flex-direction: ${(props) => props.$direction};
	flex: ${(props) => props.$flex};

	${(props) => props.$gap > 0 && `
		gap: ${props.$gap / props.theme.fontSizeBase}rem;
	`};

	${(props) => props.$justifyContent && `
		justify-content: ${props.$justifyContent};
	`};

	${(props) => props.$alignItems && `
		align-items: ${props.$alignItems};
	`};
`;

const Flex = ({
	className,
	direction = 'row',
	flex = '0 1 auto',
	gap = 0,
	justifyContent,
	alignItems,
	children,
}: PropsWithChildren<Props>) => (
	<StyledFlex
		className={className}
		$direction={direction}
		$flex={flex}
		$gap={gap}
		$justifyContent={justifyContent}
		$alignItems={alignItems}
	>
		{children}
	</StyledFlex>
);

export default Flex;
