import Image from 'next/legacy/image';
import styled, { css } from 'styled-components';

import { useTranslation } from 'next-i18next';
import onBike from '../../assets/on_bike.svg';
import onWalk from '../../assets/on_foot.svg';
import onCar from '../../assets/on_vehicule.svg';
import { convertMinuteToString } from '../../utils/time';

interface Props {
	walk?: number; // in minute
	bike?: number; // in minute
	car?: number; // in minute
}

const StyledContainer = styled.div`
	flex: 1;
	padding: ${(props) => 8 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem ${(props) => 4 / props.theme.fontSizeBase}rem ${(props) => 20 / props.theme.fontSizeBase}rem;
	display: flex;
`;

const StyledOneDirection = styled.div<{leftBorder: boolean}>`
	display: flex;
	align-items: center;

	color: ${(props) => props.theme.black};

	${(props) => props.leftBorder && css`
		// border-right: 0.5px solid ${props.theme.mapSeparator};
		border-left: 0.5px solid ${props.theme.mapSeparator};
		padding: 0 ${4 / props.theme.fontSizeBase}rem;
		margin-left: ${4 / props.theme.fontSizeBase}rem;
	`};

	${(props) => !props.leftBorder && css`
		margin-right: ${4 / props.theme.fontSizeBase}rem;
	`};
`;

const StyledText = styled.span`
	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 500;
	font-size: ${(props) => 12 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 20 / props.theme.fontSizeBase}rem;
	letter-spacing: -0.5px;
	color: ${(props) => props.theme.mapDirectionText};
	margin-left: ${(props) => 4 / props.theme.fontSizeBase}rem;
`;

export const MapOneParcoursDirections = ({
	walk, bike, car,
}: Props) => {
	const { t } = useTranslation(['common']);

	return (
		<StyledContainer>
			{ walk && <StyledOneDirection leftBorder={false}>
				<Image src={onWalk} alt="à pieds" width={16} height={16} />
				<StyledText>{ walk ? convertMinuteToString(t, walk) : '...' }</StyledText>
			</StyledOneDirection> }

			{ bike && <StyledOneDirection leftBorder={true}>
				<Image src={onBike} alt="à vélo" width={16} height={16} />
				<StyledText>{ bike ? convertMinuteToString(t, bike) : '...' }</StyledText>
			</StyledOneDirection> }

			{ car && <StyledOneDirection leftBorder={true}>
				<Image src={onCar} alt="en voiture" width={16} height={16} />
				<StyledText>{ car ? convertMinuteToString(t, car) : '...' }</StyledText>
			</StyledOneDirection> }
		</StyledContainer>
	);
};
