import { FunctionComponent, useCallback } from 'react';
import { useMap } from 'react-map-gl';
import styled from 'styled-components';

import Theme from '../../styles/Theme';
import { MapStore, MapStoreDisplay } from '../../data/MapStore';

import { MapParcoursSVG } from './svg/MapParcoursSVG';
import { IconListenSVG } from './svg/IconListenSVG';

const StyledContainer = styled.div<{heightOffsetButtons: number}>`
	position: absolute;
	right: ${(props) => 12 / props.theme.fontSizeBase}rem;
	bottom: ${(props) => props.heightOffsetButtons + 48 + 8 + 12}px;
	padding: ${(props) => 16 / props.theme.fontSizeBase}rem 0;
	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	background: ${(props) => props.theme.white};
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
	border-radius: 300px;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const StyledIconContainer = styled.div`
	position: relative;
	cursor: pointer;
`;

const StyledSeparator = styled.div`
	width: ${(props) => 24 / props.theme.fontSizeBase}rem;
	opacity: 0.16;
	border: 1px solid ${(props) => props.theme.black};
	margin: ${(props) => 12 / props.theme.fontSizeBase}rem 0;
`;

const StyledParcoursNumber = styled.div`
	position: absolute;
	right: -${(props) => 5 / props.theme.fontSizeBase}rem;
	top: -${(props) => 5 / props.theme.fontSizeBase}rem;
	width: ${(props) => 16 / props.theme.fontSizeBase}rem;
	height: ${(props) => 16 / props.theme.fontSizeBase}rem;
	border-radius: 300px;

	background: ${(props) => props.theme.mapOrange};
	color: ${(props) => props.theme.white};

	font-family: 'Plus Jakarta Sans';
	font-style: normal;
	font-weight: 800;
	font-size: ${(props) => 10 / props.theme.fontSizeBase}rem;
	line-height: ${(props) => 44 / props.theme.fontSizeBase}rem;

	display: flex;
	align-items: center;
    justify-content: center;
	letter-spacing: -0.5px;
`;

interface Props {
	defaultRouteSlug: string;
}

export const MapButtons: FunctionComponent<Props> = ({ defaultRouteSlug }: Props) => {
	const { heightOffsetButtons, display, nbParcours } = MapStore.useState((s) => s);
	const { default: map } = useMap();

	const onClick = useCallback((displayOnClick: MapStoreDisplay) => {
		MapStore.update((s) => {
			s.display = displayOnClick;
			if (displayOnClick === 'pin') {
				s.parcoursSelected = null;
				s.capsuleSelected = null;
			} else {
				s.parcoursSelected = defaultRouteSlug;
				s.capsuleSelected = null;
			}
		});
		if (map) {
			map.getMap().getStyle().layers.forEach((layer) => {
				if (layer.id.includes('parcours')) {
					map.getMap().setLayoutProperty(layer.id, 'visibility', 'none');
				}
			});
		}
	}, [defaultRouteSlug, map]);

	return (
		<StyledContainer heightOffsetButtons={heightOffsetButtons}>
			<StyledIconContainer onClick={() => onClick('pin')}>
				<IconListenSVG
					width={24}
					height={24}
					color={display === 'pin' ? Theme.mapOrange : Theme.black}
					opacity={display === 'pin' ? 1 : 0.2} />
			</StyledIconContainer>

			<StyledSeparator/>

			<StyledIconContainer onClick={() => onClick('parcours')}>
				{ display === 'parcours' && nbParcours > 0 && <StyledParcoursNumber>{ nbParcours }</StyledParcoursNumber> }
				<MapParcoursSVG
					width={24}
					height={24}
					color={display === 'parcours' ? Theme.mapOrange : Theme.black}
					opacity={display === 'parcours' ? 1 : 0.2} />
			</StyledIconContainer>
		</StyledContainer>
	);
};
